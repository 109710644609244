.axis {
  stroke-width: 0.5px;
  stroke: #888;
  font: 10px avenir next, sans-serif;
}

.axis>path {
  stroke: #888;
}

.dot {
  stroke: white;
  stroke-width: 1px;
  fill: #333;
}
