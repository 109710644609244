/* Gilroy */

@font-face {
  font-family: "GilroyExtrabold";
  src: local("GilroyExtrabold"),
    url(./fonts/Gilroy-Extrabold.otf) format("opentype");
}

@font-face {
  font-family: "GilroyBlack";
  src: local("GilroyBlack"), url(./fonts/Gilroy-Black.otf) format("opentype");
}

@font-face {
  font-family: "GilroyBlackItalic";
  src: local("GilroyBlackItalic"),
    url(./fonts/Gilroy-BlackItalic.otf) format("opentype");
}

@font-face {
  font-family: "GilroyBold";
  src: local("GilroyBold"), url(./fonts/Gilroy-Bold.otf) format("opentype");
}

@font-face {
  font-family: "GilroyBoldItalic";
  src: local("GilroyBoldItalic"),
    url(./fonts/Gilroy-BoldItalic.otf) format("opentype");
}

@font-face {
  font-family: "GilroyHeavy";
  src: local("GilroyHeavy"), url(./fonts/Gilroy-Heavy.otf) format("opentype");
}

@font-face {
  font-family: "GilroyHeavyItalic";
  src: local("GilroyHeavyItalic"),
    url(./fonts/Gilroy-HeavyItalic.otf) format("opentype");
}

@font-face {
  font-family: "GilroyLight";
  src: local("GilroyLight"), url(./fonts/Gilroy-Light.otf) format("opentype");
}

@font-face {
  font-family: "GilroyLightItalic";
  src: local("GilroyLightItalic"),
    url(./fonts/Gilroy-LightItalic.otf) format("opentype");
}

@font-face {
  font-family: "GilroyMedium";
  src: local("GilroyMedium"), url(./fonts/Gilroy-Medium.otf) format("opentype");
}

@font-face {
  font-family: "GilroyMediumItalic";
  src: local("GilroyMediumItalic"),
    url(./fonts/Gilroy-MediumItalic.otf) format("opentype");
}

@font-face {
  font-family: "GilroyRegular";
  src: local("GilroyRegular"),
    url(./fonts/Gilroy-Regular.otf) format("opentype");
}

@font-face {
  font-family: "GilroyRegularItalic";
  src: local("GilroyRegularItalic"),
    url(./fonts/Gilroy-RegularItalic.otf) format("opentype");
}

@font-face {
  font-family: "GilroySemibold";
  src: local("GilroySemibold"),
    url(./fonts/Gilroy-Semibold.otf) format("opentype");
}

@font-face {
  font-family: "GilroySemiboldItalic";
  src: local("GilroySemiboldItalic"),
    url(./fonts/Gilroy-SemiboldItalic.otf) format("opentype");
}

@font-face {
  font-family: "GilroyThin";
  src: local("GilroyThin"), url(./fonts/Gilroy-Thin.otf) format("opentype");
}

@font-face {
  font-family: "GilroyThinItalic";
  src: local("GilroyThinItalic"),
    url(./fonts/Gilroy-ThinItalic.otf) format("opentype");
}

@font-face {
  font-family: "GilroyUltraLight";
  src: local("GilroyUltraLight"),
    url(./fonts/Gilroy-UltraLight.otf) format("opentype");
}

@font-face {
  font-family: "GilroyUltraLightItalic";
  src: local("GilroyUltraLightItalic"),
    url(./fonts/Gilroy-UltraLightItalic.otf) format("opentype");
}

@font-face {
  font-family: "You2013";
  src: local("You2013"), url(./fonts/You2013.otf) format("opentype");
}
